import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { i18n } from './localization'
import VCalendar from 'v-calendar'
import vueSmoothScroll from 'vue3-smooth-scroll'



createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VCalendar, {})
  .use(Antd)
  .use(PerfectScrollbar)
  .use(vueSmoothScroll)
  .mount('#app')
