import localeAntd from 'ant-design-vue/lib/locale-provider/ar_EG'

const messages = {
  topBar: {
    presidency: 'رئاسة الجمهورية',
    issuesHistory: 'مسائل',
    projectManagement: 'المشروع',
    typeToSearch: 'بحث...',
    findPages: 'بحث عن صفحات ...',
    actions: 'أجراءات',
    status: 'مكانة',
    profileMenu: {
      hello: 'مرحبا',
      billingPlan: 'خطة الفواتير',
      role: 'دور',
      email: 'بريد إلكتروني',
      phone: 'هاتف',
      editProfile: 'تعديل الملف الشخصي',
      logout: 'تسجيل خروج',
    },
  },
  general: {
    seeAll: 'الكل',
    noData: 'لا معطياة',
    eventList: 'قائمة المواعيد',
    downloads: 'التحميلات',
    download: 'تحميل',
  },
  footer: {
    ourCoord: 'عناوننا',
    adress: 'العنوان',
    phone: 'الهاتف',
    email: 'البريد الإلكتروني',
    subscribe: 'الاشتراك في الرسائل الإخبارية',
    subscribeMsg: 'عن طريق الاشتراك في قائمتنا البريدية سوف يكون دائما تحديث مع أحدث الأخبار من الموفق',
    name: 'الإسم و اللقب',
    send: 'إرسال',
    followus: 'تابعونا',
    followussc: 'تابعونا على مواقع التواصل الإجتماعي',
    newsletter: 'النشرة الإخبارية',
    erremail: 'أدخل البريد الإلكتروني الصحيح',
    errchamp: 'املأ كل الفراغات',
    err: 'خطأ أثناء التسجيل',
    succ: 'اكتمل التسجيل بنجاح',
    siteUtiles: 'مواقع مفيدة',
  },
  menu: {
    menu: 'الروابط',
    home: 'الرئيسية',
    about: 'بخصوص',
    presentation: 'التقديم',
    contact: 'الاتصال',
    news: 'الاخبار',
    posts: 'المنشورات',
    bibs: 'المكتبة',
    agenda: 'البرنامج',
    debates: 'إستفتاء',
    polls: 'إستفتاء',
    information: 'النفاذ إلى المعلومات',
    web: 'الصور و الفيديوهات',
    video: 'الفيديوهات',
    gallery: 'الصور',
    search: 'بحث',
    //type publication
    eco: 'المجالات الإقتصاديّة والماليّة',
    socite: 'التوازنات الاجتماعية و العقد الاجتماعي',
    geopolitique: 'قطاع الفلاحة والأمن الغذائي والمائي',
    newTech: 'الإنتقال الرقمي والذكاء الإصطناعي',
    politiqueC: 'الدولة والإصلاحات السياسية',
    sanitaire: 'المنظومة الصحيّــة',
    militaire: 'الإنتقال الإيكولوجي و الطّاقي',
    //information
    chargee: 'المسؤول على النفاذ إلى المعلومة',
    textejuridique: 'المراجع القانونية',
    politique: 'سياسة النفاذ إلى المعلومة',
    demande: 'مطلب النفاذ إلى المعلومة',
    bilan: 'التقارير',
    copyRights: 'حق النشر © 2021 ITES, جميع الحقوق محفوظة.',
    podcast: 'البودكاست',
  },
  search: {
    search: 'بحث',
    all: 'الكل',
    news: 'أنباء',
    posts: 'المنشورات',
    bibs: 'مكتبة',
    agenda: 'برنامج',
    debates: 'إستفتاء',
    polls: 'إستفتاء',
    noResult: 'لا نتائج',
  },
  presentation: {
    subtitle: 'المعهد التونسي للدراسات الإستراتيجيّة',
    presentation1: '.أحدث المعهد التونسي للدراسات الإستراتيجية بصدور</a>القانون عدد 103 لسنة 1993 <a href="/resources/Loi n° 93-103 du 25 Octobre 1993 (Fr).pdf" class= "link-danger" target="_blank">المؤرخ في  25/10/1993',
    presentation2: 'ويضطلع المعهد بمهمة البحث والدراسة والتحليل وإستشراف آفاق المستقبل القريب والبعيد حول كل المسائل ذات العلاقة بالأحداث والتطوّرات الظرفية والظواهر المختلفة على الصعيدين الوطني والعالمي والتي يمكن أن تكون لها صلة بمسار التنمية للمجتمع التونسي في كل أبعادها السياسية والإقتصادية والإجتماعية والثقافية بهدف تفسير هذه المسائل وتوضيحها أو استباق تطوراتها عبر صياغة التصوّرات والفرضيات المختلفة لذلك، وكذلك حول المسائل التي يكلّف بها في نطاق هذه المهمة.',
    presentation3: 'وبما أن المعهد تابع لرئاسة الجمهورية، فإن مهمته الرئيسية تكمن في مساعدة صناع القرار على تلبية الاحتياجات الوطنية في عالم يمرّ بفجوة عميقة وذلك من خلال إجراء الدراسات والتحليلات والإطلاع عن كثب على آخر المستجدات على الساحتين الوطنية والدولية في المجالات ذات الأهمية الإستراتيجية لتونس.',
    presentation4: '',
  },
  history: {
    title: 'تاريخ',
    founded: 'FONDÉ EN 1993',
    text1: 'Régi par la loi n°93-103 du 25 octobre 1993, l’ITES est un établissement d’utilité publique à caractère non administratif. Ses modalités de fonctionnement, son organisation administrative et financière ainsi que la composition et les attributions de son conseil consultatif sont prévues par le décret n)598-95 du 3 avril 1995.',
    text2: 'Soumis à la tutelle de la Présidence de la République, l’ITES a pour principale vocation de contribuer à éclairer la prise de décision stratégique auprès des décideurs et répondre aux besoins nationaux dans un monde en profonde mutation.',
    text3: 'Sa mission est de mener des études et des analyses stratégiques sur les questions dont il est saisi par la Présidence de la République et d’assurer une fonction de veille tant au niveau national qu’international sur des domaines jugés stratégiques pour le pays.',
  },
  organisation: {
    title: 'التنظيم',
    dep1: {
      title: 'Départements Développement régional',
      text1: 'Ce département est centré sur l’analyse des problèmes et des défis de développement des régions et des territoires. Par une étude comparative des réalités entre la Tunisie et d’autres pays dans le monde.',
      text2: 'Le programme au sein de ce service, vise à trouver une nouvelle stratégie pour le développement régional en identifiant les problèmes structurels majeurs dans les régions.',
    },
    dep2: {
      title: 'Département Géopolitique',
      text1: 'Il s’agit de problèmes régionaux et de voisinage pouvant aboutir à des conflits ou à des décisions politiques aux conséquences pour notre pays.',
      text2: 'Des études sont menées au sein de ce département avec une équipe de travail composée d’experts pour identifier, évaluer ces menaces et anticiper les solutions.',
      text3: 'Cette discipline pratiquée au sein de l’ITES orientée vers l’action permet d’éclairer les politiques quant à la prise de décision à court, moyen et long terme sur les enjeux touchant le devenir de la Tunisie.',
    },
    dep3: {
      title: 'Département de la jeunesse',
      text1: 'La Tunisie compte une population essentiellement jeune et diplômée.',
      text2: '250 000 Jeunes, filles et garçons diplômés sont en attente d’emploi.',
      text3: 'C’est à cette jeunesse que le département pense et souhaiterait faire rêver en leur offrant des opportunités de dignité par le travail.',
      text4: 'La réflexion, le débat, les échanges dans un esprit d’innovation peuvent proposer des solutions à ce défi de développement et transformer ainsi la société tunisienne.',
    },
    dep4: {
      title: 'Département Prospective',
      text1: 'Le Département prospective est chargé d’élaborer des scenarios afin d’explorer de nouveaux domaines. L’enjeu étant d’anticiper au mieux les évolutions de notre société. Il a pour mission de guider et d’informer le décideur dans la définition et la mise en œuvre de ses orientations stratégiques en matière économique, sociale, environnementale ou technologique. Proposer des politiques publiques innovantes, adaptées à ces enjeux.',
    },
    dep5: {
      title: 'Département Réseaux et Partenariats',
      text1: 'Le partenariat est essentiel dans toute forme d’action de développement.',
      text2: 'Il s’agit donc pour l’ITES de créer un réseau avec des partenaires ciblés pour les secteurs stratégiques.',
      text3: 'Cette politique voulue par l’Institut facilite par l’expertise l’accès à l’innovation. Ceci dans le but de se substituer à l’action traditionnelle sectorisée.',
      text4: 'Ce département permet également à l’ITES d’intervenir efficacement au niveau national et international en qualité de Think Tank.',
    },
    organigrame: 'التنظيمية',
  },
  vocation: {
    title: 'المهمة',
    text1: 'البحث والتحليل والتوقع',
    text2: 'النقاشات المستنيرة',
    text3: 'النشر والتأثير',
  },
  team: {
    title: 'الفريق',
    subtitle: 'فريقنا ',
    director: 'المدير العام',
    expert: 'خبير في ITES',
  },
  parteners: {
    title: 'الشركاء',
  },
  contact: {
    title: 'الاتصال',
    formTitle: 'نموذج الاتصال',
    name: 'الإ سم',
    email: 'البريد الإلكتروني',
    cin: 'بطاقة التعريف الوطنية',
    phone: 'الهاتف',
    hphone: 'الهاتف',
    message: 'رسالة',
    hname: 'الإ سم*',
    hemail: 'البريد الإلكتروني*',
    hcin: 'بطاقة التعريف الوطنية',
    hmessage: 'رسالة*',
    send: 'إرسال',
  },
  access: {
    title: "طلب معلومات",
    fullName: "الإسم و اللقب",
    adress: "العنوان",
    email: "البريد الإلكتروني",
    phone: "الهاتف",
    fax: "الفاكس",
    pp: "الشخص المعني",
    requestedInformation: "المعلومات مطلوبة ",
    type: "اختر شكل الحصول على المعلومات",
    papier: "كنسخة ورقية",
    electronique: "كنسخة إلكترونية",
    extrait: "مقتطفات من المعلومات",
    responsable: {
      name: " السيد محمد ضاوي",
      post: "متصرف عام ",
      dep: "مدير وحدة المصالح المشتركة  ",
      tel: "71 727 803",
      email: "dhaoui.mhamed@ites.tn",

    },
  },
  texteJuridique: {
    ref: "المراجع القانونية المتعلقة بالنفاذ إلى المعلومة",
    loi1: "	قانون أساسي عدد 22 لسنة 2016  مؤرخ في 24 مارس 2016 يتعلق بالحق في النفاذ إلى المعلومة",
    loi2: "	منشور عدد 19 بتاريخ 18 ماي 2018 حول الحق في النفاذ إلى المعلومة",
    guide: "	دليل النفاذ إلى المعلومة",
    form: "استمارة طلب النفاذ إلى المعلومة",
    download: "	اضغط لتحميل الاستمارة",
    form2: "	مطلب تظلم حول طلب النفاذ إلى معلومة",

  },
}

export default {
  locale: 'ar-EG',
  localeAntd,
  messages,
}