<template>
  <!-- Footer -->
  <div>
    <footer
      class="bg-secondary text-center text-white"
      style="background-color: rgba(0, 0, 0, 0.2)"
    >
      <!-- Grid container -->
      <div class="container p-4">
        <!-- Section: Social media -->
        <section class="mb-4"></section>
        <!-- Section: Social media -->

        <!-- Section: Form -->
        <section class="">
          <form action="">
            <div class="row d-flex justify-content-center">
              <div class="col-auto">
                <h4 class="text-white">{{ $t('footer.subscribe') }}</h4>
                <h6 class="text-white">{{ $t('footer.subscribeMsg') }}</h6>
              </div>
            </div>
            <!--Grid row-->
            <div class="row d-flex justify-content-center">
              <!--Grid column-->
              <div class="col-md-4 col-md-4 mt-md-0 col-12 mt-2 col-12">
                <input
                  type="text"
                  v-model="name"
                  class="form-control"
                  :placeholder="$t('footer.name') + '*'"
                />
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-md-4 mt-md-0 col-12 mt-2">
                <!-- Email input -->
                <div class="form-outline form-white mb-4">
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    :placeholder="$t('footer.email') + '*'"
                  />
                </div>
              </div>
              <!--Grid column-->

              <!--Grid column-->
              <div class="col-auto">
                <!-- Submit button -->
                <input
                  type="submit"
                  @click="subscribe"
                  :value="$t('footer.send')"
                  class="btn btn-primary rounded-0 py-2"
                />
              </div>
              <!--Grid column-->
            </div>
            <!--Grid row-->
          </form>
        </section>
        <!-- Section: Form -->

        <!-- Section: Links -->
        <section class="mt-4">
          <!--Grid row-->
          <div class="row">
            <!--Grid column-->
            <div class="col-lg-2 col-md-6 mb-4 mb-md-0">
              <h4 class="text-primary">
                <strong>{{ $t('menu.menu') }}</strong>
              </h4>
              <a-divider class="bg-primary"></a-divider>

              <ul class="list-unstyled mb-0">
                <li class="mb-2">
                  <a @click="scrollTop('/news')" class="ahover">{{ $t('menu.news') }}</a>
                </li>
                <li class="mb-2">
                  <a @click="scrollTop('/posts')" class="mb-4 ahover">{{ $t('menu.posts') }}</a>
                </li>
                <li class="mb-2">
                  <a @click="scrollTop('/agenda')" class="mb-4 ahover">{{ $t('menu.agenda') }}</a>
                </li>
                <li class="mb-2">
                  <a @click="scrollTop('/polls')" class="ahover">{{ $t('menu.polls') }}</a>
                </li>
              </ul>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
              <div v-if="informations">
                <h4 class="text-primary">
                  <strong>{{ $t('footer.ourCoord') }}</strong>
                </h4>
                <a-divider class="bg-primary"></a-divider>
                <p class="text-white">{{ $t('footer.adress') }} : {{ informations.adress }}</p>
                <p class="text-white">
                  {{ $t('footer.phone') }} : &#x200E;{{ informations.phone }}
                </p>
                <p class="text-white">{{ $t('footer.email') }} : {{ informations.email }}</p>
              </div>
            </div>
            <!--Grid column-->
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div>
                <h4 class="text-primary">
                  <strong>{{ $t('footer.siteUtiles') }}</strong>
                </h4>
                <a-divider class="bg-primary"></a-divider>
                <p class="text-white">
                  <u
                    ><a href="https://www.carthage.tn" target="_blank" rel="noopener noreferrer"
                      >www.carthage.tn</a
                    ></u
                  >
                </p>
                <p class="text-white">
                  <u
                    ><a href="http://mediateur.tn/" target="_blank" rel="noopener noreferrer"
                      >www.mediateur.tn</a
                    >
                  </u>
                </p>
                <p class="text-white">
                  <u
                    ><a href="http://www.csdhlf.tn/" target="_blank" rel="noopener noreferrer"
                      >www.csdhlf.tn</a
                    >
                  </u>
                </p>
              </div>
            </div>
            <!--Grid column-->
            <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h4 class="text-primary">
                <strong>{{ $t('footer.followus') }}</strong>
              </h4>
              <a-divider class="bg-primary"></a-divider>
              <p class="text-white">{{ $t('footer.followussc') }}</p>

              <!-- Facebook -->
              <a
                class="btn btn-primary btn-floating m-1"
                :href="informations.facebook"
                role="button"
                target="_blank"
                ><i class="fe fe-facebook"></i
              ></a>

              <!-- Twitter -->
              <a
                class="btn btn-primary btn-floating m-1"
                :href="informations.twitter"
                role="button"
                target="_blank"
                ><i class="fe fe-twitter"></i
              ></a>

              <!-- Google -->
              <a
                class="btn btn-primary btn-floating m-1"
                :href="informations.youtube"
                role="button"
                target="_blank"
                ><i class="fe fe-youtube"></i
              ></a>

              <!-- Instagram -->
              <a
                class="btn btn-primary btn-floating m-1"
                :href="informations.instagram"
                target="_blank"
                ><i class="fe fe-instagram"></i
              ></a>

              <!-- Linkedin -->
              <a
                class="btn btn-primary btn-floating m-1"
                :href="informations.linkedin"
                target="_blank"
                role="button"
                ><i class="fe fe-linkedin"></i
              ></a>
            </div>
            <!--Grid column-->
          </div>

          <!--Grid row-->
        </section>
        <!-- Section: Links -->
      </div>
      <!-- Grid container -->
    </footer>
    <!-- Footer -->
    <footer class="text-center text-white" style="background-color: #222">
      <div class="container p-1">
        <div class="row">
          <div class="col">
            <h6 class="text-primary m-1">{{ $t('menu.copyRights') }}</h6>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import apiAxios from '@/services/axios'
import axios from 'axios'

export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
  data: () => ({
    name: null,
    email: null,
    error: null,
    informations: {},
    showErrorAlert: false,
    showSuccAlert: false,
    apiUrl: process.env.VUE_APP_API_URL,
  }),
  created() {
    this.getInformations()
  },
  methods: {
    openNotification(type, title, desc) {
      this.$notification[type]({
        message: title,
        description: desc,
      })
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    scrollTop(val) {
      this.$router.push(val)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    subscribe(event) {
      event.preventDefault()
      this.showErrorAlert = false
      this.showSuccAlert = false
      if (this.name && this.email) {
        if (this.validateEmail(this.email)) {
          apiAxios
            .put(this.apiUrl + '/public/newsletters', {
              data: { fullName: this.name, email: this.email },
            })
            .then((res) => {
              console.log(res)
              this.openNotification('success', this.$t('footer.newsletter'), this.$t('footer.succ'))
            })
            .catch((e) => {
              console.log(e)
              this.openNotification('error', this.$t('footer.newsletter'), this.$t('footer.err'))
            })
            .finally(() => {
              this.email = null
              this.name = null
            })
        } else {
          this.openNotification('error', this.$t('footer.newsletter'), this.$t('footer.erremail'))
        }
      } else {
        this.openNotification('error', this.$t('footer.newsletter'), this.$t('footer.errchamp'))
      }
    },
    getInformations() {
      apiAxios
        .post('/cordonnees/filter', {
          query: {},
        })
        .then((res) => {
          this.informations = res.data[0]
        })
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss" scoped>
@import '@/mixins';
a {
  color: $white;
}

a:hover {
  color: $primary;
}

.ahover:hover {
  color: $primary;
}
</style>
