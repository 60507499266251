import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'

const messages = {
  topBar: {
    presidency: 'THE PRESIDENCY OF THE REPUBLIC',
    issuesHistory: 'Issues',
    projectManagement: 'Project',
    typeToSearch: 'Search...',
    findPages: 'Find pages...',
    actions: 'Actions',
    status: 'Status',
    profileMenu: {
      hello: 'Hello',
      billingPlan: 'Billing Plan',
      role: 'Role',
      email: 'Email',
      phone: 'Phone',
      editProfile: 'Edit Profile',
      logout: 'Logout',
    },
  },
  general: {
    seeAll: 'See all',
    noData: 'No data',
    eventList: 'Events list',
    downloads: 'Downloads',
    download: 'Download',
  },
  footer: {
    ourCoord: 'Our coordinates',
    adress: 'Address',
    phone: 'Phone',
    email: 'Email',
    subscribe: 'Subscribe to our newsletter',
    subscribeMsg: 'Subscribe to the TISS newsletter to be notified of each new publication.',
    name: 'Full Name',
    send: 'Send',
    followus: 'Follow Us',
    followussc: 'Follow us on social media',
    newsletter: 'Newsletter',
    erremail: 'Enter a correct email!',
    errchamp: 'Fill in all the fields!',
    err: 'Error in subscription',
    succ: 'Successful subscription',
    siteUtiles: 'Useful websites',

  },
  menu: {
    menu: 'Menu',
    home: 'Home',
    about: 'About',
    presentation: 'Presentation',
    contact: 'Contact',
    news: 'News',
    bibs: 'Library',
    posts: 'Publications',
    agenda: 'Agenda',
    debates: 'Polls',
    polls: 'Polls',
    information: 'Access to information',
    web: 'Pictures and videos',
    video: 'Videos',
    gallery: 'Gallery',
    search: 'Search',
    //type publication
    eco: 'Economic dynamics and reforms',
    socite: 'Social equilibrium and social contract',
    geopolitique: 'Agriculture and food industry',
    newTech: 'Digital Transition',
    sanitaire: 'Sanitary System',
    militaire: 'Ecological and Energy Transition',
    politiqueC: 'State and Political reforms',

    //information
    chargee: 'Information Access Officer',
    textejuridique: 'Legal texts',
    politique: 'TISS Policy',
    demande: 'Information request',
    bilan: 'Reports',
    copyRights: 'Copyright © 2021 TISS, All rights reserved.',
    podcast: 'PodCast',

  },
  search: {
    search: 'Search',
    all: 'All',
    news: 'News',
    bibs: 'Library',
    posts: 'Publications',
    agenda: 'Agenda',
    debates: 'Polls',
    polls: 'Polls',
    noResult: 'No result',
  },
  presentation: {
    subtitle: 'TUNISIAN INSTITUTE FOR STRATEGIC STUDIES',
    
    presentation1: 'TISS is a large-scale Tunisian think tank with diverse research areas. It was established by Law No. <a href="/resources/Loi n° 93-103 du 25 Octobre 1993 (Fr).pdf" class= "link-danger" target="_blank">103-93 on October 25, 1993 </a> . It deals with strategic multidimensional fields related to the country&#39;s future.Therefore, it is positioned at the heart of the country&#39;s political, economic and social challenges as an instrument to support public decision-making.',
    presentation2: 'The Institute focuses on future public policies in order to facilitate decision-making in matters of fundamental reforms required by the democratic process which our country is engaged in.',
    presentation3: 'As a space for multidisciplinary reflection and a forum for exchange and debate, the TISS adopts a global approach focused on development, cooperation as well as promoting Tunisia to be an active player on the national and international scenes.',
    presentation4: 'Being under the tutelage of the Presidency of the Republic, the TISS&#39;s main vocation is to provide assistance for decision - makers to meet national needs in a world undergoing profound changes. The TISS’s mission is to carry out studies and strategic analyses on the issues referred to it by the Presidency of the Republic and to keep a close eye on the latest developments in the national and international scenes in areas that are of a strategic importance to Tunisia.',
  },
  history: {
    title: 'History',
    founded: 'FONDÉ EN 1993',
    text1: 'Régi par la loi n°93-103 du 25 octobre 1993, l’ITES est un établissement d’utilité publique à caractère non administratif. Ses modalités de fonctionnement, son organisation administrative et financière ainsi que la composition et les attributions de son conseil consultatif sont prévues par le décret n)598-95 du 3 avril 1995.',
    text2: 'Soumis à la tutelle de la Présidence de la République, l’ITES a pour principale vocation de contribuer à éclairer la prise de décision stratégique auprès des décideurs et répondre aux besoins nationaux dans un monde en profonde mutation.',
    text3: 'Sa mission est de mener des études et des analyses stratégiques sur les questions dont il est saisi par la Présidence de la République et d’assurer une fonction de veille tant au niveau national qu’international sur des domaines jugés stratégiques pour le pays.',
  },
  organisation: {
    title: 'Organization',
    dep1: {
      title: 'Départements Développement régional',
      text1: 'Ce département est centré sur l’analyse des problèmes et des défis de développement des régions et des territoires. Par une étude comparative des réalités entre la Tunisie et d’autres pays dans le monde.',
      text2: 'Le programme au sein de ce service, vise à trouver une nouvelle stratégie pour le développement régional en identifiant les problèmes structurels majeurs dans les régions.',
    },
    dep2: {
      title: 'Département Géopolitique',
      text1: 'Il s’agit de problèmes régionaux et de voisinage pouvant aboutir à des conflits ou à des décisions politiques aux conséquences pour notre pays.',
      text2: 'Des études sont menées au sein de ce département avec une équipe de travail composée d’experts pour identifier, évaluer ces menaces et anticiper les solutions.',
      text3: 'Cette discipline pratiquée au sein de l’ITES orientée vers l’action permet d’éclairer les politiques quant à la prise de décision à court, moyen et long terme sur les enjeux touchant le devenir de la Tunisie.',
    },
    dep3: {
      title: 'Département de la jeunesse',
      text1: 'La Tunisie compte une population essentiellement jeune et diplômée.',
      text2: '250 000 Jeunes, filles et garçons diplômés sont en attente d’emploi.',
      text3: 'C’est à cette jeunesse que le département pense et souhaiterait faire rêver en leur offrant des opportunités de dignité par le travail.',
      text4: 'La réflexion, le débat, les échanges dans un esprit d’innovation peuvent proposer des solutions à ce défi de développement et transformer ainsi la société tunisienne.',
    },
    dep4: {
      title: 'Département Prospective',
      text1: 'Le Département prospective est chargé d’élaborer des scenarios afin d’explorer de nouveaux domaines. L’enjeu étant d’anticiper au mieux les évolutions de notre société. Il a pour mission de guider et d’informer le décideur dans la définition et la mise en œuvre de ses orientations stratégiques en matière économique, sociale, environnementale ou technologique. Proposer des politiques publiques innovantes, adaptées à ces enjeux.',
    },
    dep5: {
      title: 'Département Réseaux et Partenariats',
      text1: 'Le partenariat est essentiel dans toute forme d’action de développement.',
      text2: 'Il s’agit donc pour l’ITES de créer un réseau avec des partenaires ciblés pour les secteurs stratégiques.',
      text3: 'Cette politique voulue par l’Institut facilite par l’expertise l’accès à l’innovation. Ceci dans le but de se substituer à l’action traditionnelle sectorisée.',
      text4: 'Ce département permet également à l’ITES d’intervenir efficacement au niveau national et international en qualité de Think Tank.',
    },
    organigrame: 'Organigram',
  },
  vocation: {
    title: 'Vocation',
    text1: 'RESEARCH, ANALYSIS AND ANTICIPATION',
    text2: 'INFORMED AND EDUCATED DEBATE',
    text3: 'DISSEMINATION AND IMPACT',
  },
  team: {
    title: 'Team',
    subtitle: 'Our team ',
    director: 'Chief Executive Officer',
    expert: 'TISS expert',
  },
  parteners: {
    title: 'Partners',
  },
  contact: {
    title: 'Contact',
    formTitle: 'Contact form',
    name: 'Name',
    email: 'Email',
    cin: 'CIN',
    phone: 'Phone',
    hphone: 'Phone',
    message: 'Text',
    hname: 'Name*',
    hemail: 'Email*',
    hcin: 'CIN',
    hmessage: 'Text*',
    send: 'Send',
  },
  access: {
    title: "Information request",
    fullName: "Full Name",
    adress: "Address",
    email: "Email",
    phone: "Phone",
    fax: "Fax",
    pp: "Status",
    requestedInformation: "Required information ",
    type: "Choose the way you want to get the information",
    papier: "In the form of a hard copy",
    electronique: "In the form of an electronic copy",
    extrait: "Extract of information",
    responsable: {
      name: "Dhaoui Mhamed",
      post: "Administrateur général",
      dep: "Directeur de l'unité des services communs",
      tel: "71 727 803",
      email: "dhaoui.mhamed@ites.tn",

    },
  },
  texteJuridique: {
    ref: "Références juridiques relatives à l'accès à l'information",
    loi1: "Loi organique n° 22-2016 du 24 mars 2016 relative au droit d'accès à l'information",
    loi2: "Circulaire n°19 du 18 mai 2018 concernant l'accès à l'information",
    guide: "Guide de l'accès à l'information",
    form: "Formulaire d'accès à l'information",
    download: "Télécharger le formulaire",
    form2: "Formulaire de Plainte concernant l'accès à une information",

  },
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}