import localeAntd from 'ant-design-vue/lib/locale-provider/fr_FR'

const messages = {
  topBar: {
    presidency: 'LA PRÉSIDENCE DE LA RÉPUBLIQUE',
    issuesHistory: 'Problèmes',
    projectManagement: 'Projets',
    typeToSearch: 'Chercher...',
    findPages: 'Trouver des pages...',
    actions: 'Actes',
    status: 'Statut',
    profileMenu: {
      hello: 'Bonjour',
      billingPlan: 'Plan de facturation',
      role: 'Rôle',
      email: 'Email',
      phone: 'Téléphone',
      editProfile: 'Editer le profil',
      logout: 'Connectez - Out',
    },
  },
  general: {
    seeAll: 'Voir tout',
    noData: 'Pas de données',
    eventList: 'Liste des évènements',
    downloads: 'Téléchargements',
    download: 'Télécharger',
    pollStatsTitle: 'Résultats',
  },
  footer: {
    ourCoord: 'Nos coordonnées',
    adress: 'Adresse',
    phone: 'Téléphone',
    email: 'Email',
    subscribe: 'Inscrivez vous à notre newsletter',
    subscribeMsg: 'Merci d\'être inscrit à la newsletter de l\'ITES pour être notifié pour chaque nouvelle publication.',
    name: 'Nom & Prénom',
    send: 'Envoyer',
    followus: 'Suivez-nous',
    followussc: 'Suivez-nous su les réseaux sociaux',
    newsletter: 'Newsletter',
    erremail: 'Inserer un email correct!',
    errchamp: 'Remplir tous les champs!',
    err: 'Erreur lors d\'inscription',
    succ: 'Inscription effectuée avec succès',
    siteUtiles: 'Sites utiles',//new
  },
  menu: {
    menu: 'Menu',
    home: 'Accueil',
    about: 'À propos',
    presentation: 'Présentation',
    contact: 'Contact',
    news: 'Actualités',
    posts: 'Publications',
    bibs: 'Bibliothèque',
    agenda: 'Agenda',
    debates: 'Sondages',
    polls: 'Sondages',
    information: 'accès a l\'information',
    video: 'Videos',
    web: 'Web TV',
    gallery: 'Gallerie',
    search: 'Recherche',
    //type publication
    eco: 'Dynamiques et réformes économiques',
    socite: 'Equilibres sociaux et contrat social',
    geopolitique: 'Agriculture et industries agro-alimentaires',
    newTech: 'Transition numérique et digitale',
    sanitaire: 'Système Sanitaire',
    militaire: 'Transition Ecologique et Energétique',
    politiqueC: 'État et réformes politiques',
    //information
    chargee: 'Responsable d\'accées a l\'information',
    textejuridique: 'Textes juridiques',
    politique: 'Politique de l\'ites',
    demande: 'Demande d\'information',
    bilan: 'Rapport',
    copyRights: 'Copyright © 2021 ITES, Tous droits réservés.',
    podcast: 'PodCast',

  },
  search: {
    search: 'Recherche',
    all: 'Tous',
    news: 'actualités',
    posts: 'publications',
    bibs: 'Bibliothèque',
    agenda: 'agenda',
    debates: 'sondages',
    polls: 'sondages',
    noResult: 'Pas de résultat',
  },
  presentation: {
    subtitle: 'Institut Tunisien des Etudes Strategiques',
    presentation1: 'Think tank tunisien orienté vers la recherche Stratégique et prospective, l’ITES a été crée par <a href="/resources/Loi n° 93-103 du 25 Octobre 1993 (Fr).pdf" class= "link-danger" target="_blank"> la  loi n°103-93 du 25 octobre 1993.</a>',
    presentation2: 'l’ITES traite les problématiques visant à éclairer le décideur sur les enjeux stratégiques présent et de l’avenir. Instrument d’aide à la décision publique et de pilotage du changement, il se positionne de manière singulière au niveau national au cœur des enjeux politiques, économiques, géopolitiques et sociaux du pays.',
    presentation3: 'Les travaux de l’Institut visent à mettre l’accent sur les politiques publiques de demain afin de faciliter la prise de décision en matière de réforme de fond qu’appelle le processus démocratique dans lequel notre pays s’est engagé.',
    presentation4: 'Espace de réflexion pluridisciplinaire, forum d’échange et de débat, l’ITES tend vers une influence globale dédiée au développement, à la valorisation de l’intelligence collective, à la coopération ainsi qu’à la promotion d’un engagement actif de la Tunisie sur la scène nationale et internationale.',
  },
  history: {
    title: 'Historique',
    founded: 'FONDÉ EN 1993',
    text1: 'Régi par la loi n°93-103 du 25 octobre 1993, l’ITES est un établissement d’utilité publique à caractère non administratif. Ses modalités de fonctionnement, son organisation administrative et financière ainsi que la composition et les attributions de son conseil consultatif sont prévues par le décret n)598-95 du 3 avril 1995.',
    text2: 'Soumis à la tutelle de la Présidence de la République, l’ITES a pour principale vocation de contribuer à éclairer la prise de décision stratégique auprès des décideurs et répondre aux besoins nationaux dans un monde en profonde mutation.',
    text3: 'Sa mission est de mener des études et des analyses stratégiques sur les questions dont il est saisi par la Présidence de la République et d’assurer une fonction de veille tant au niveau national qu’international sur des domaines jugés stratégiques pour le pays.',
  },
  organisation: {
    title: 'Organigramme',
    dep1: {
      title: 'Départements Développement régional',
      text1: 'Ce département est centré sur l’analyse des problèmes et des défis de développement des régions et des territoires. Par une étude comparative des réalités entre la Tunisie et d’autres pays dans le monde.',
      text2: 'Le programme au sein de ce service, vise à trouver une nouvelle stratégie pour le développement régional en identifiant les problèmes structurels majeurs dans les régions.',
    },
    dep2: {
      title: 'Département Géopolitique',
      text1: 'Il s’agit de problèmes régionaux et de voisinage pouvant aboutir à des conflits ou à des décisions politiques aux conséquences pour notre pays.',
      text2: 'Des études sont menées au sein de ce département avec une équipe de travail composée d’experts pour identifier, évaluer ces menaces et anticiper les solutions.',
      text3: 'Cette discipline pratiquée au sein de l’ITES orientée vers l’action permet d’éclairer les politiques quant à la prise de décision à court, moyen et long terme sur les enjeux touchant le devenir de la Tunisie.',
    },
    dep3: {
      title: 'Département de la jeunesse',
      text1: 'La Tunisie compte une population essentiellement jeune et diplômée.',
      text2: '250 000 Jeunes, filles et garçons diplômés sont en attente d’emploi.',
      text3: 'C’est à cette jeunesse que le département pense et souhaiterait faire rêver en leur offrant des opportunités de dignité par le travail.',
      text4: 'La réflexion, le débat, les échanges dans un esprit d’innovation peuvent proposer des solutions à ce défi de développement et transformer ainsi la société tunisienne.',
    },
    dep4: {
      title: 'Département Prospective',
      text1: 'Le Département prospective est chargé d’élaborer des scenarios afin d’explorer de nouveaux domaines. L’enjeu étant d’anticiper au mieux les évolutions de notre société. Il a pour mission de guider et d’informer le décideur dans la définition et la mise en œuvre de ses orientations stratégiques en matière économique, sociale, environnementale ou technologique. Proposer des politiques publiques innovantes, adaptées à ces enjeux.',
    },
    dep5: {
      title: 'Département Réseaux et Partenariats',
      text1: 'Le partenariat est essentiel dans toute forme d’action de développement.',
      text2: 'Il s’agit donc pour l’ITES de créer un réseau avec des partenaires ciblés pour les secteurs stratégiques.',
      text3: 'Cette politique voulue par l’Institut facilite par l’expertise l’accès à l’innovation. Ceci dans le but de se substituer à l’action traditionnelle sectorisée.',
      text4: 'Ce département permet également à l’ITES d’intervenir efficacement au niveau national et international en qualité de Think Tank.',
    },
    organigrame: 'Organigramme',
  },
  vocation: {
    title: 'Vocation',
    text1: 'LA RECHRECHE, L\'ANALYSE ET L\'ANTICIPATION',
    text2: 'LES DÉBATS ÉCLAIRÉS ET INFORMÉS',
    text3: 'LA DIFFUSION ET L’IMPACT',
  },
  team: {
    title: 'Équipe',
    subtitle: 'Notre Équipe ',
    director: 'Directeur général',
    expert: 'Expert à l\'ITES',
  },
  parteners: {
    title: 'Partenaires',
  },
  contact: {
    title: 'Contact',
    formTitle: 'Formulaire de contact',
    name: 'Nom',
    email: 'Email',
    cin: 'Cin',
    phone: 'Téléphone',
    hphone: 'Téléphone',
    message: 'Message',
    hname: 'Nom*',
    hemail: 'Email*',
    hcin: 'Cin',
    hmessage: 'Message*',
    send: 'Envoyer',
  },
  access: {
    title: "Demande d'information",
    fullName: "Nom et prénom",
    adress: "Adresse",
    email: "Email",
    phone: "Téléphone",
    fax: "fax",
    pp: "statut",
    requestedInformation: "Information requise ",
    type: "Choisissez la forme d'obtention des informations",
    papier: "Sous forme de copie papier",
    electronique: "sous forme de copie électronique",
    extrait: "Extraits d'informations",
    responsable: {
      name: "Dhaoui Mhamed",
      post: "Administrateur général",
      dep: "Directeur de l'unité des services communs",
      tel: "71 727 803",
      email: "dhaoui.mhamed@ites.tn",

    },

  },
  texteJuridique: {
    ref: "Références juridiques relatives à l'accès à l'information",
    loi1: "Loi organique n° 22-2016 du 24 mars 2016 relative au droit d'accès à l'information",
    loi2: "Circulaire n°19 du 18 mai 2018 concernant l'accès à l'information",
    guide: "Guide de l'accès à l'information",
    form: "Formulaire d'accès à l'information",
    download: "Télécharger le formulaire",
    form2: "Formulaire de Plainte concernant l'accès à une information",

  },
}

export default {
  locale: 'fr-FR',
  localeAntd,
  messages,
}
