import axios from 'axios'

const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/admin",
  'Content-Type': 'application/json',
})

apiAxios.interceptors.request.use((request) => {
  return request
}, error => {
  return Promise.reject(error)
})

export default apiAxios
