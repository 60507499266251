<template>
  <div
    :class="{
      [$style.footerDark]: true,
      [$style.footerFullWidth]: !settings.isContentMaxWidth,
    }"
  >
    <div class="" :class="$style.outer">
      <div :class="$style.container">
        <div class="row">
          <div class="col-12 col-sm-6 p-5">
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12">
                    <router-link :to="'/home'">
                     <img src="/img/logo/itesWhite.png">
                    </router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                  
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12" v-if="informations">
                    <h4 class="text-white">{{$t('footer.ourCoord')}}</h4>
                    <a-divider></a-divider>
                    <p class="text-white"> {{$t('footer.adress')}} : {{informations.adress}} </p>
                    <p class="text-white"> {{$t('footer.phone')}} : {{informations.phone}} </p>
                    <p class="text-white"> {{$t('footer.email')}} : {{informations.email}} </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-12 p-5 bg-secondary">
            <h4 class="text-white">{{$t('footer.subscribe')}}</h4>
            <h6 class="text-white">{{$t('footer.subscribeMsg')}}</h6>
            <div class="row">
              <div class=" col-6">
                <input
                  type="text"
                  v-model="name"
                  class="form-control"
                  :placeholder="$t('footer.name') + '*'"
                />
              </div>
              <div class="col-6">
                <input
                  type="email"
                  v-model="email"
                  class="form-control"
                  :placeholder="$t('footer.email') + '*'"
                />
              </div>
            </div>
        
            <div class="mt-2">
                     <input type="submit" @click="subscribe"  :value="$t('footer.send')" class="btn btn-primary rounded-0 py-2">
            </div>
            <div class="row pt-4" v-if="informations">
                  <div class="col-12">
                    <h4 class="text-white">{{$t('footer.followus')}}</h4>
                    <a-divider></a-divider>
                    <p class="text-white"> {{$t('footer.followussc')}} </p>
                    <div class="social-media">
                    <a class="selectable" :href="informations.facebook" target="_blank"><i class="pr-2 selectable fe fe-facebook" /></a>
                    <a class="selectable" :href="informations.twitter" target="_blank"><i class="pr-2 selectable fe fe-twitter" /></a>
                    <a class="selectable" :href="informations.youtube" target="_blank"><i class="pr-2 selectable fe fe-youtube" /></a>
                    <a  class="selectable" :href="informations.linkedin" target="_blank"><i class="pr-2 selectable fe fe-linkedin" /></a>
                    <a  class="selectable" :href="informations.instagram" target="_blank"><i class="selectable fe fe-instagram" /></a>

                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import apiAxios from '@/services/axios'


export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
  data: () => ({
    name: null,
    email: null,
    error: null,
    informations: {},
    showErrorAlert: false,
    showSuccAlert: false,
  }),
  created() {
    this.getInformations()
  },
  methods: {
    openNotification(type, title, desc) {
      this.$notification[type]({
        message: title,
        description:
          desc,
      });
    },
    validateEmail(email){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    subscribe(){
      this.showErrorAlert=false
      this.showSuccAlert=false
      if(this.name && this.email){
        if(this.validateEmail(this.email)){
           apiAxios.put('/newsletters',{
                data: {fullName: this.name, email: this.email},
            }).then((res)=>{
              console.log(res)
                this.openNotification('success',this.$t('footer.newsletter'),this.$t('footer.succ'))
            }).catch(e => {
              console.log(e)
                this.openNotification('error',this.$t('footer.newsletter'),this.$t('footer.err'))
            })
            .finally(()=>{
              this.email = null
              this.name = null
            })
        }else{
          this.openNotification('error',this.$t('footer.newsletter'),this.$t('footer.email'))
        }
      }else{
        this.openNotification('error',this.$t('footer.newsletter'),this.$t('footer.champ'))
      }
    },
    getInformations(){
      apiAxios.post('/cordonnees/filter',{
                query:{},
            }).then((res)=>{
                this.informations=res.data[0];
            })
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';

</style>
<style lang="scss" scoped>
@import '@/mixins';
a{
  color: $white;
}

a:hover{
  color: $primary;
}
</style>
