<template>
  <div class="app-bar">
    <div class="row justify-content-between align-items-center">
      <div class="col-md-6 col-12">
        <img class="ml-2 mr-2" src="/img/tn.png" />
        <span>{{$t('topBar.presidency')}}</span>
      </div>

      <div class="col-lg-5 col-md-5 col-none p-2">
        <div class="row justify-content-end">
            <div class="col-3">
                <vb-language-switcher />
            </div>
          <div class="col-6 col-lg-4" v-if="informations">
            <a-divider type="vertical" />
                    <a class="selectable" :href="informations.facebook" target="_blank"><i class="pr-2 selectable fe fe-facebook" /></a>
                    <a class="selectable" :href="informations.twitter" target="_blank"><i class="pr-2 selectable fe fe-twitter" /></a>
                    <a class="selectable" :href="informations.youtube" target="_blank"><i class="pr-2 selectable fe fe-youtube" /></a>
                    <a  class="selectable" :href="informations.linkedin" target="_blank"><i class="pr-2 selectable fe fe-linkedin" /></a>
                    <a  class="selectable" :href="informations.instagram" target="_blank"><i class="selectable fe fe-instagram" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VbLanguageSwitcher from './LanguageSwitcher'
import apiAxios from '@/services/axios'
export default { 
    components:{
         VbLanguageSwitcher,
    },
    data: () => ({
    informations: {},
  }),
  created() {
    this.getInformations()
  },
  methods: {
    getInformations(){
      apiAxios.post('/cordonnees/filter',{
                query:{},
            }).then((res)=>{
                this.informations=res.data[0];
            })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.app-bar {
  background-color: #1d527b;
  height: 2.4rem;
  color: $white;
}

.selectable:hover {
  color: $primary;
  transition: 0.2s;
}

a{
  color: $white;
}

a:hover{
  color: $primary;
}
</style>
