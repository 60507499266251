<template>
  <div>
  <custom-app-bar/>
  
  <div :class="$style.topmenu">
    <div :class="$style.row" class="row justify-content-between">
      <div class="col-3">
      <div :class="$style.logoContainer" style="
    margin-left: 25px; margin-right: 25px;
">
      <div :class="$style.logo">
        <router-link :to="'/home'">
          <img src="/img/logo/itesWhite.png">
        </router-link>
      </div>
    </div>
      </div>
      <div class="col-3">
      <search-div />
      </div>
    </div>
  </div>
  <div
    id="Menu"
    :class="{
      [$style.menu]: true,
      [$style.white]: true,
      arabicDirection: settings.locale === 'ar-EG'
    }"
  >
  <div class="row justify-content-center">
    <div class="col">
    <div :class="$style.navigation">
      <a-menu :mode="'horizontal'" :selected-keys="selectedKeys" @click="handleClick">
        <template v-for="item in menuData">
          <template v-if="!item.roles || item.roles.includes(user.role)">
            <item
              v-if="!item.children && !item.category"
              :key="item.key"
              :menu-info="item"
              :styles="$style"
            />
            <sub-menu v-if="item.children" :key="item.key" :menu-info="item" :styles="$style" />
          </template>
        </template>
      </a-menu>
    </div>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { default as localStore } from 'store'
import find from 'lodash/find'
import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'
import CustomAppBar from '@/components/CustomAppBar'
import SearchDiv from '@/components/SearchDiv'

export default {
  name: 'MenuTop',
  components: { SubMenu, Item, CustomAppBar, SearchDiv },
  setup() {
    const store = useStore()
    const route = useRoute()
    const menuData = computed(() => getMenuData)
    const selectedKeys = ref([])
    const openKeys = ref([])
    const settings = computed(() => store.getters.settings)
    const isMenuCollapsed = computed(() => store.getters.settings.isMenuCollapsed)
    const user = computed(() => store.getters['user/user'])
    const pathname = computed(() => route.pathname)
    

    const onCollapse = (collapsed, type) => {
      const value = !settings.value.isMenuCollapsed
      store.commit('CHANGE_SETTING', { setting: 'isMenuCollapsed', value })
    }

    const handleClick = e => {
      if (e.key === 'settings') {
        store.commit('CHANGE_SETTING', {
          setting: 'isSettingsOpen',
          value: true,
        })
        return
      }
      localStore.set('app.menu.selectedKeys', [e.key])
      selectedKeys.value = [e.key]
    }

    const handleOpenChange = openKeys => {
      localStore.set('app.menu.openedKeys', openKeys)
      openKeys.value = openKeys
    }

    const setSelectedKeys = () => {
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData.value.concat(), 'children'), [
        'url',
        pathname,
      ])
      selectedKeys.value = selectedItem ? [selectedItem.key] : []
    }

    onMounted(() => {
      openKeys.value = localStore.get('app.menu.openedKeys') || []
      selectedKeys.value = localStore.get('app.menu.selectedKeys') || []
      setSelectedKeys()
    })

    watch(pathname, () => setSelectedKeys())
    watch(isMenuCollapsed, () => (openKeys.value = []))

    return {
      menuData,
      selectedKeys,
      openKeys,
      settings,
      user,
      onCollapse,
      handleClick,
      handleOpenChange,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style scoped>
.top {
  top: 0px;
}
.sticky {
    background:#3a3939ee;
    height: 3.6em;
    transition: ease-in-out 0.2s;
    border-bottom: solid 2px #ce2129ee;
    box-shadow: 0px 15px 10px -15px #111;    
  }
  .arabicDirection{
   direction: rtl;
  }
  .ant-menu-submenu-title {
  color: $white !important;
}

.ant-menu-submenu-title:hover {
  color: $secondary !important;
}
</style>
