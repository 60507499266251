export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: 'menu.home',
    key: 'eadzea',
    url: '/',
  },
  {
    title: 'menu.news',
    key: 'z1zhd',
    url: '/news',
  },
  {
    title: 'menu.posts',
    key: 'jmpmo',
    url: '/posts',
    children: [
      {
        title: 'menu.eco',
        key: '456dz1',
        url: '/posts/60ba2e530527c73c806171dc',
      },
      {
        title: 'menu.socite',
        key: '456dz2',
        url: '/posts/60ba2e780527c73c806171dd',
      },
      {
        title: 'menu.geopolitique',
        key: '456dz3',
        url: '/posts/60ba2e8f0527c73c806171de',
      },
      {
        title: 'menu.newTech',
        key: '456dz4',
        url: '/posts/60ba2e9d0527c73c806171df',
      },
      {
        title: 'menu.sanitaire',
        key: '456dz5',
        url: '/posts/60ba2ead0527c73c806171e0',
      },
      {
        title: 'menu.militaire',
        key: '456dz5',
        url: '/posts/60ba2ebf0527c73c806171e1',
      },
      {
        title: 'menu.politiqueC',
        key: '456dz5',
        url: '/posts/60761076701a301ea427d44f',
      },
    ],
  },

  {
    title: 'menu.agenda',
    key: 'x81fgy',
    url: '/agenda',
  },
  {
    title: 'menu.web',
    key: 'oafs0k',
    children: [
      {
        title: 'menu.video',
        key: '456dzfx',
        url: '/videos',
      },
      {
        title: 'menu.gallery',
        key: 'qsdzf',
        url: '/gallery',
      },
      {
        title: 'menu.podcast',
        key: '456dzf',
        url: '/podcast',
      },
    ],
  },
  {
    title: 'menu.information',
    key: '5xtagv',
    children: [
      {
        title: 'menu.textejuridique',
        key: 'textejuridique',
        url: '/access/textejuridique',
      },
      {
        title: 'menu.chargee',
        key: 'chargee',
        url: '/access/chargee',
      },
      {
        title: 'menu.bilan',
        key: 'bilan',
        url: '/access/bilan',
      },
      {
        title: 'menu.demande',
        key: 'demande',
        url: '/access/demande',
      },
    ],
  },
  /* {
     title: 'menu.polls',
     key: '5tyyud',
     url: '/polls',
   },*/
  {
    title: 'menu.bibs',
    key: 'azdlo',
    url: '/bibs',
  },
  {
    title: 'menu.about',
    key: 'ozfs0k',
    children: [
      {
        title: 'menu.presentation',
        key: 'cvferm',
        children: [
          {
            title: 'menu.presentation',
            key: 'presentation',
            url: '/about/presentation#presentation',
            internal: '#presentation',
          },
          {
            title: 'organisation.title',
            key: 'organisation',
            url: '/about/presentation#organisation',
            internal: '#organisation',
          },
          {
            title: 'vocation.title',
            key: 'vocation',
            url: '/about/presentation#vocation',
            internal: '#vocation',
          },
          {
            title: 'team.title',
            key: 'team',
            url: '/about/presentation#team',
            internal: '#team',
          },
          {
            title: 'parteners.title',
            key: 'parteners',
            url: '/about/presentation#parteners',
            internal: '#parteners',
          },
        ],
      },
      {
        title: 'menu.contact',
        key: 'c58jtf',
        url: '/about/contact',
      },
    ],
  },
  // VB:REPLACE-END:MENU-CONFIG
]
