import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import MainLayout from '@/layouts/Main'

const router = createRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  hash: false,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      // VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT
      redirect: '/home',
      component: MainLayout,
      children: [
        // VB:REPLACE-START:ROUTER-CONFIG
        {
          path: '/home',
          name: 'home',
          meta: { title: 'Accueil' },
          component: () => import('./views/home'),
        },
        {
          path: '/search/:cat/:search',
          name: 'search',
          meta: { title: 'Recherche' },
          component: () => import('./views/search'),
        },
        {
          path: '/news',
          name: 'news',
          meta: { title: 'Actualités' },
          component: () => import('./views/news'),
        },

        {
          path: '/news/:id',
          name: 'singleNews',
          meta: { title: 'Actualité' },
          component: () => import('./views/singleNews'),
        },
        {
          path: '/posts/:type',
          name: 'postst',
          meta: { title: 'Publications' },
          component: () => import('./views/posts'),
        },
        {
          path: '/posts',
          name: 'posts',
          meta: { title: 'Publications' },
          component: () => import('./views/posts'),
        },
        {
          path: '/post/:id',
          name: 'post',
          meta: { title: 'Publication' },
          component: () => import('./views/post'),
        },
        {
          path: '/bibs/:type',
          name: 'bibs',
          meta: { title: 'Bibliothèque' },
          component: () => import('./views/bibs'),
        },
        {
          path: '/bibs',
          name: 'bibs',
          meta: { title: 'Bibliothèque' },
          component: () => import('./views/bibs'),
        },
        {
          path: '/bib/:id',
          name: 'bib',
          meta: { title: 'Bibliothèque' },
          component: () => import('./views/bib'),
        },
        {
          path: '/agenda',
          name: 'agenda',
          meta: { title: 'Agenda' },
          component: () => import('./views/agenda'),
        },
        {
          path: '/polls',
          name: 'polls',
          meta: { title: 'Sondages' },
          component: () => import('./views/polls'),
        },
        {
          path: '/poll/:id',
          name: 'poll',
          meta: { title: 'Débat' },
          component: () => import('./views/poll'),
        },
        {
          path: '/about',
          name: 'about',
          component: () => import('./views/about'),
          children: [{
            path: 'presentation',
            name: 'presentation',
            meta: { title: 'À propos' },
            component: () => import('./views/about/presentation'),
          },
          {
            path: 'contact',
            name: 'contact',
            meta: { title: 'Contact' },
            component: () => import('./views/about/contact'),
          },
          ],
        },
        {
          path: '/access',
          name: 'access',
          meta: { title: 'Information' },
          component: () => import('./views/about'),
          children: [{
            path: 'textejuridique',
            name: 'textejuridique',
            meta: { title: 'Texte juridique' },
            component: () => import('./views/access/textejuridique'),
          },
          {
            path: 'politique',
            name: 'politique',
            meta: { title: 'Politique de l\'ites' },
            component: () => import('./views/access/politique'),
          },
          {
            path: 'chargee',
            name: 'chargee',
            meta: { title: 'Responsable d\'accées a l\'information' },
            component: () => import('./views/access/chargee'),
          },
          {
            path: 'demande',
            name: 'demande d\'information',
            meta: { title: 'Demande d\'information' },
            component: () => import('./views/access/demande'),
          },
          {
            path: 'bilan',
            name: 'bilan',
            meta: { title: 'Blian' },
            component: () => import('./views/access/bilan'),
          },
          {
            path: 'bilan/:id',
            name: 'detailsbilan',
            meta: { title: 'bilans' },
            component: () => import('./views/access/bilan/bilanDetails'),
          },
          ],
        },
        {
          path: '/videos',
          name: 'videos',
          meta: { title: 'Videos' },
          component: () => import('./views/videos'),
        },
        {
          path: '/podcast',
          name: 'podcast',
          meta: { title: 'podcast' },
          component: () => import('./views/podcast'),
        },
        {
          path: '/gallery',
          name: 'gallery',
          meta: { title: 'Gallerie' },
          component: () => import('./views/gallery'),
        },
        {
          path: '/gallery/:id',
          name: 'album',
          meta: { title: 'Gallerie' },
          component: () => import('./views/album'),
        },

        // VB:REPLACE-END:ROUTER-CONFIG
      ],
    },

    // System Pages
    {
      path: '/error404',
      name: 'route404',
      meta: {
        title: 'Error 404',
      },
      component: () => import('./views/auth/404'),
    },
    {
      path: '/error500',
      meta: {
        title: 'Error 500',
      },
      component: () => import('./views/auth/500'),
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  /*NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)*/

  next()
})

export default router
