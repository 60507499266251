<template>
  <footer class="footer-section">
        <div class="container">
            <div class="footer-cta pt-5 pb-5">
                <div class="row" v-if="informations">
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fe fe-map-pin"></i>
                            <div class="cta-text">
                                <h4>{{$t('footer.adress')}}</h4>
                                <span>{{informations.adress}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fe fe-phone"></i>
                            <div class="cta-text">
                                <h4>{{$t('footer.phone')}}</h4>
                                <span>{{informations.phone}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fe fe-mail"></i>
                            <div class="cta-text">
                                <h4>{{$t('footer.email')}}</h4>
                                <span>{{informations.email}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-lg-4 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="/"><img src="/img/logo/itesWhite.png" class="img-fluid" alt="logo"></a>
                            </div>
                            <div class="footer-social-icon" v-if="informations">
                              <div class="footer-widget-heading">
                                <h3>{{$t('footer.followus')}}</h3>
                              </div>
                                <a :href="informations.facebook"><i class="fe fe-facebook icon-bg"></i></a>
                                <a :href="informations.twitter"><i class="fe fe-twitter icon-bg"></i></a>
                                <a :href="informations.youtube"><i class="fe fe-youtube icon-bg"></i></a>
                                <a :href="informations.linkedin"><i class="fe fe-linkedin icon-bg"></i></a>
                                <a :href="informations.instagram"><i class="fe fe-instagram icon-bg"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>{{$t('menu.menu')}}</h3>
                            </div>
                            <ul>
                              <li><a  @click="scrollTop('/home')"  class="ahover">{{$t('menu.home')}}</a></li>
                                <li><a  @click="scrollTop('/news')"  class="ahover">{{$t('menu.news')}}</a></li>
                                <li><a  @click="scrollTop('/posts')"  class=" mb-4  ahover">{{$t('menu.posts')}}</a></li>
                                <li> <a @click="scrollTop('/agenda')"   class=" mb-4 ahover">{{$t('menu.agenda')}}</a></li>
                                <li><a @click="scrollTop('/polls')"  class="ahover">{{$t('menu.polls')}}</a></li>
                                <li><a  @click="scrollTop('/videos')"  class=" mb-4  ahover">{{$t('menu.video')}}</a></li>
                                <li><a @click="scrollTop('/gallery')"  class="ahover">{{$t('menu.gallery')}}</a></li>
                                <li> <a @click="scrollTop('/about/contact')"   class=" mb-4 ahover">{{$t('menu.contact')}}</a></li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>{{$t('footer.subscribe')}}</h3>
                            </div>
                            <div class="footer-text mb-25">
                                <p>{{$t('footer.subscribeMsg')}}</p>
                            </div>
                            <div class="subscribe-form">
                                <form action="#">
                                    <input
                                      type="text"
                                      v-model="name"
                                      :placeholder="$t('footer.name') + '*'"
                                    />
                                    <input
                                      type="email"
                                      v-model="email"
                                      :placeholder="$t('footer.email') + '*'"
                                    />
                                    <button><i class="fe fe-send"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import apiAxios from '@/services/axios'


export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
  data: () => ({
    name: null,
    email: null,
    error: null,
    informations: {},
    showErrorAlert: false,
    showSuccAlert: false,
  }),
  created() {
    this.getInformations()
  },
  methods: {
    openNotification(type, title, desc) {
      this.$notification[type]({
        message: title,
        description:
          desc,
      });
    },
    validateEmail(email){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
   scrollTop(val){
     this.$router.push(val)
         window.scrollTo({top: 0, behavior: 'smooth'});
   },
      subscribe(){
      this.showErrorAlert=false
      this.showSuccAlert=false
      if(this.name && this.email){
        if(this.validateEmail(this.email)){
           apiAxios.put('/newsletters',{
                data: {fullName: this.name, email: this.email},
            }).then((res)=>{
              console.log(res)
                this.openNotification('success',this.$t('footer.newsletter'),this.$t('footer.succ'))
            }).catch(e => {
              console.log(e)
                this.openNotification('error',this.$t('footer.newsletter'),this.$t('footer.err'))
            })
            .finally(()=>{
              this.email = null
              this.name = null
            })
        }else{
          this.openNotification('error',this.$t('footer.newsletter'),this.$t('footer.email'))
        }
      }else{
        this.openNotification('error',this.$t('footer.newsletter'),this.$t('footer.champ'))
      }
    },
    getInformations(){
      apiAxios.post('/cordonnees/filter',{
                query:{},
            }).then((res)=>{
                this.informations=res.data[0];
            })
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
<style lang="scss" scoped>
@import './style.module.scss';
ul {
    margin: 0px;
    padding: 0px;
}
.footer-section {
  background: $secondary;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #585858;
}
.single-cta i {
  color: $primary;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
  width: 80%;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #919191;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.icon-bg:hover {
  color: $primary;
}

.icon-bg {
  font-size: 20px;
}

.facebook-bg{
  background: #3B5998;
}
.twitter-bg{
  background: #55ACEE;
}
.google-bg{
  background: #DD4B39;
}

.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
      color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: $primary;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover{
  color: $primary;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 44%;
  padding: 14px 5px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}
.subscribe-form button {
    position: absolute;
    right: 0;
    background: $primary;
    padding: 13px 20px;
    border: 1px solid $primary;
    top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area{
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a{
  color: $primary;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a{
  color: $primary;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
</style>
