<template>
  <a-menu-item :key="menuInfo.key" v-bind="$props" :disabled="menuInfo.disabled">
    <router-link v-if="menuInfo.url" :to="menuInfo.url">
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
      <span :class="styles.title">{{ $t(menuInfo.title) }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
    </router-link>
    <!--<a v-if="menuInfo.url && menuInfo.internal" :href="menuInfo.internal" v-smooth-scroll>
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]" />
      <span :class="styles.title">{{ $t(menuInfo.title) }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
    </a>
    <router-link v-if="menuInfo.url && menuInfo.internal" :to="menuInfo.url" v-slot="{ route, navigate, isActive }">
    <NavLink :active="isActive" :href="menuInfo.internal" @click="navigate" v-smooth-scroll>
      {{ route.fullPath }}</NavLink
  >
    </router-link>-->
    <span v-if="!menuInfo.url" :to="menuInfo.url">
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"></i>
      <span :class="styles.title">{{ $t(menuInfo.title) }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
    </span>
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: {
      type: Object,
      default: () => {},
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
