import { createStore } from 'vuex'
import user from './user'
import settings from './settings'

export default createStore({
  modules: {
    user,
    settings,
  },
  state: {},
  mutations: {},
  actions: {FB_Parse() {
      setTimeout(() => {
        window.FB.XFBML.parse()
      }, 0)
    }},
  getters: {},
})
