<template>
  <div>
    <div class="mb-auto">
      <h4 class="text-secondary"></h4>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          v-model="search"
          :placeholder="$t('search.search')"
        />
        <select class="form-control" v-model="cat">
          <option selected value="all">{{ $t('search.all') }}</option>
          <option value="news">{{ $t('search.news') }}</option>
          <option value="posts">{{ $t('search.posts') }}</option>
          <option value="bib">{{ $t('search.bibs') }}</option>
          <option value="debates">{{ $t('search.debates') }}</option>
        </select>
        <div class="input-group-append">
          <button @click="sendTosearch" class="btn btn-primary" type="button">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    search: null,
    cat: 'all',
  }),
  methods: {
    sendTosearch() {
      this.$router.push({ name: 'search', params: { cat: this.cat, search: this.search } })
    },
  },
  mounted() {
    var self = this
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
        self.sendTosearch()
      }
    })
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.form-control {
  border-color: $primary;
}
</style>