<template>
  <div>
    <div class="vb__utils__sidebar__buttons">
        <a
          class="vb__utils__sidebar__button"
          @click="scrollToTop"
          v-if="show"
        >
          <i class="fe fe-arrow-up" />
        </a>
    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
     show: false,
  }),
  mounted(){
    window.addEventListener('scroll', this.scrollListener)
  },
  beforeUnmount: function () {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods:{
     scrollToTop:function(){
       window.scrollTo({top: 0, behavior: 'smooth'});
     },
     scrollListener: function (e) {
      this.show = window.scrollY > 200
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
