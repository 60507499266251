<template>
  <div class="app-bar">
    <div class="row justify-content-between align-items-top">
      <div class="col-9 col-md-8">
        <img class="ml-2 mr-2" src="/img/tn.png" />
        <span>{{$t('topBar.presidency')}}</span>
      </div>
     
      <div class="col-3 col-md-4">
            <vb-language-switcher />
      </div>
    </div>
  </div>
</template>
<script>
import VbLanguageSwitcher from './LanguageSwitcher'
export default { 
    components:{
         VbLanguageSwitcher,
    },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.app-bar {
  background-color: #217dc4;
  height: 2.4rem;
  color: $white;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.selectable:hover {
  color: $primary;
  transition: 0.2s;
}

@media (max-width: 600px) {
   .app-bar {
  background-color: #217dc4;
  color: $white;
}
}
</style>
